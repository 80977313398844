import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getUsersByGuId } from '../../Reducers/UserReducer';
import {
  getSubscriptionData,
  getCandidatesConnectInfo,
  getConversationPersonMapping,
  getPersonsById,
  getOrgPhoneConsentInfo,
} from '../../Reducers/ConnectReducer';
import * as NotificationActions from '../../Actions/NotificationActions';
import MessagePopup from '../../Components/Connect/MessagePopup/MessagePopup';
import { getMessageConversations } from '../../Reducers/MessageConversationsReducer';
import { getJobGuidToIdMapping, getJobsById } from '../../Reducers/JobReducer';
import { getMessages } from '../../Reducers/ChatConversationsReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import * as ChatActions from '../../Actions/ChatActions';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as AryaNotifyActions from '../../Actions/AryaNotifyActions';
import { getNotifications, getMessagePopupNotificationPayload } from '../../Reducers/AryaNotifyReducer';
import { readPhoneNumberSMS } from '../../Repository/ConnectRepository';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { fetchBasicJobsInfo as _fetchBasicJobsInfo } from '../../Actions/JobActions';
import { getJobsBasicInfoById, getBasicInfoJobGuidToIdMapping } from '../../Reducers/JobsBasicInfoReducer';

const mapStateToProps = (state, ownProps) => ({
  messageConversations: getMessageConversations(state, ownProps.conversationId),
  chatMessages: getMessages(state, ownProps.conversationId),
  candidatesConnectInfo: getCandidatesConnectInfo(state),
  connectInfo: getCandidatesConnectInfo(state),
  conversationPersonMapping: getConversationPersonMapping(state),
  personsById: getPersonsById(state),
  connectNotifications: getNotifications(state, 'Connect'),
  jobsById: getJobsById(state),
  jobGuidToIdMapping: getJobGuidToIdMapping(state),
  featureToggleList: getFeatureToggleList(state),
  userByGuId: getUsersByGuId(state),
  subscriptiondata: getSubscriptionData(state, ownProps.personId),
  connectApiStatus: getApiStatus(state, 'ConnectStatus'),
  notificationPayload: getMessagePopupNotificationPayload(state, ownProps.personId),
  basicInfoJobGuidToIdMapping: getBasicInfoJobGuidToIdMapping(state),
  jobsBasicInfoById: getJobsBasicInfoById(state),
  orgPhoneConsentInfo: getOrgPhoneConsentInfo(state, ownProps.personId),
});

const mapDispatchToProps = {
  setMessagePopupVisibility: NotificationActions.setMessagePopupVisibility,
  fetchChatMessages: ChatActions.fetchMessages,
  fetchMessages: ConnectActions.fetchMessages,
  fetchConnectStatus: ConnectActions.fetchConnectStatus,
  sendChatMessage: ChatActions.sendMessage,
  addNewMessageToConversation: ConnectActions.addNewMessageToConversation,
  markConversationAsRead: ConnectActions.markConversationAsRead,
  markNotificationAsRead: AryaNotifyActions.markNotificationAsRead,
  saveRecentReadNotificationInfo: AryaNotifyActions.saveRecentReadNotificationInfo,
  fetchBasicJobsInfo: _fetchBasicJobsInfo,
  fetchOrgPhoneConsentInfo: ConnectActions.fetchOrgPhoneConsentInfo,
};

class MessagePopupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: null,
      phones: null,
      selectedPhone: null,
      consentStatus: null,
      consentInfo: null,
      jobDetails: {},
      isJobDetailsFetched: false,
    };

    this.onCloseMessagePopup = this.onCloseMessagePopup.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.markConversationRead = this.markConversationRead.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  static getDerivedStateFromProps(props, prevState) {
    const updatedState = {};
    const {
      messageConversations,
      chatMessages,
      messageType,
      personId,
      connectInfo,
      personNumber,
      fetchBasicJobsInfo,
      orgPhoneConsentInfo,
      conversationId,
    } = props;
    const { selectedPhone, jobDetails, isJobDetailsFetched } = prevState;
    let messages =
      messageType === 'SMS'
        ? messageConversations[selectedPhone]?.[conversationId]?.Messages || []
        : chatMessages || [];

    const candidateConnectedInfo = _.get(connectInfo, ['ConnectStatuses', personId], null);
    const phones = _.get(candidateConnectedInfo, ['Contact', 'Phones'], []);

    const phoneNumbers = phones.map(p => p.Number);
    let phoneDefaultValue = selectedPhone || personNumber;

    if (messages) {
      // Set message direction
      messages = messages.map(message => {
        const isByCandidate = !phoneNumbers.includes(message.To);
        return {
          ...message,
          isByCandidate,
        };
      });

      // Filter current dropdown messages
      if (!phoneDefaultValue) {
        if (!messages || messages.length === 0) {
          [phoneDefaultValue] = phoneNumbers;
        } else {
          phoneDefaultValue = phoneNumbers.includes(messages[0].To) ? messages[0].To : messages[0].From;
        }
      }
      if (messageType === 'SMS') {
        messages = messages.filter(m => m.To === phoneDefaultValue || m.From === phoneDefaultValue);
      }
      if (messages.length > 0) messages.reverse();
    }
    const consentStatus = MessagePopupContainer.getMessageConsentStatus(phoneDefaultValue, phones);
    const consentInfo = MessagePopupContainer.getMessageConsentInfo(phoneDefaultValue, phones);
    const refId = orgPhoneConsentInfo?.[personNumber]?.RefId;
    if (refId && !isJobDetailsFetched) {
      fetchBasicJobsInfo({
        jobGuids: [refId],
      });
      updatedState.isJobDetailsFetched = true;
    }
    let _jobDetails = jobDetails;
    const jobGuid = consentInfo?.RefId;
    const { basicInfoJobGuidToIdMapping, jobsBasicInfoById } = props;
    const deniedConsentJobId = basicInfoJobGuidToIdMapping?.[refId];
    if (basicInfoJobGuidToIdMapping && refId && deniedConsentJobId && jobsBasicInfoById[deniedConsentJobId]) {
      _jobDetails = jobsBasicInfoById[deniedConsentJobId];
    }
    updatedState.messages = messages;
    updatedState.consentStatus = consentStatus;
    updatedState.selectedPhone = phoneDefaultValue;
    updatedState.phones = phones;
    updatedState.consentInfo = consentInfo;
    updatedState.jobDetails = _jobDetails;
    return updatedState;
  }

  //! TODO: move to util? Or a component?
  static getMessageConsentStatus(selectedPhone, phones) {
    const phone = phones.find(p => p.Number === selectedPhone);
    return phone ? phone.MessageConsentStatus : undefined;
  }

  static getMessageConsentInfo(selectedPhone, phones) {
    const phone = phones.find(p => p.Number === selectedPhone);
    return phone ? phone.MessageConsentInfo : undefined;
  }

  componentDidMount() {
    const { conversationId, personId, fetchOrgPhoneConsentInfo } = this.props;
    const { selectedPhone } = this.state;
    fetchOrgPhoneConsentInfo([selectedPhone], personId);

    this.fetchData();
    this.markConversationRead();
    if (conversationId) readPhoneNumberSMS(conversationId, selectedPhone);
  }

  componentDidUpdate(prevProps, prevState) {
    const { conversationId } = this.props;
    const { selectedPhone, jobDetails, isJobDetailsFetched } = this.state;
    if (prevProps.conversationId !== conversationId) {
      this.markConversationRead();
    }

    const {
      basicInfoJobGuidToIdMapping,
      jobsBasicInfoById,
      orgPhoneConsentInfo,
      fetchBasicJobsInfo,
      fetchOrgPhoneConsentInfo,
      personId,
    } = this.props;

    if (prevState.selectedPhone !== selectedPhone && !orgPhoneConsentInfo?.[selectedPhone]) {
      fetchOrgPhoneConsentInfo([selectedPhone], personId);
    }
    const refId = orgPhoneConsentInfo?.[selectedPhone]?.RefId;
    if (
      prevProps.orgPhoneConsentInfo?.[selectedPhone] !== orgPhoneConsentInfo?.[selectedPhone] &&
      refId &&
      !isJobDetailsFetched &&
      !basicInfoJobGuidToIdMapping?.[refId]
    ) {
      fetchBasicJobsInfo({
        jobGuids: [refId],
      });
      this.setState({
        isJobDetailsFetched: true,
      });
    }

    const messageConsentInfoForSelectedPhone = orgPhoneConsentInfo?.[selectedPhone];
    const selectedPhoneRefId = messageConsentInfoForSelectedPhone?.RefId;
    const deniedConsentJobId = basicInfoJobGuidToIdMapping?.[selectedPhoneRefId];

    if (
      selectedPhoneRefId &&
      (Object.keys(jobDetails ?? {})?.length === 0 || jobDetails?.JobGuid !== selectedPhoneRefId) &&
      deniedConsentJobId &&
      jobsBasicInfoById?.[deniedConsentJobId]
    ) {
      this.setState(
        {
          isJobDetailsFetched: true,
          jobDetails: jobsBasicInfoById[deniedConsentJobId],
        },
        () => {}
      );
    }
  }

  componentWillUnmount() {
    const { conversationId } = this.props;
    const { selectedPhone } = this.state;
    this.markConversationRead();
    if (conversationId) readPhoneNumberSMS(conversationId, selectedPhone);
  }

  onCloseMessagePopup(id) {
    const { setMessagePopupVisibility, personId, messageType } = this.props;
    const { selectedPhone } = this.state;
    setMessagePopupVisibility(id, personId, false, messageType, selectedPhone);
  }

  onChangePhone(phone) {
    this.setState({
      selectedPhone: phone,
    });
    const { conversationId, fetchMessages } = this.props;
    if (conversationId) {
      fetchMessages(conversationId, null, null, 'SMS', phone);
    }
  }

  markConversationRead() {
    const { conversationId, markConversationAsRead, markNotificationAsRead, messageType } = this.props;
    markConversationAsRead(conversationId, messageType);
    markNotificationAsRead({
      Source: 'Connect',
      Types: [messageType],
      RefId: conversationId,
    });
  }

  fetchData() {
    const {
      conversationId,
      fetchMessages,
      messageType,
      fetchChatMessages,
      personId,
      personNumber,
      conversationPersonMapping,
      fetchConnectStatus,
    } = this.props;
    if (conversationId) {
      fetchMessages(conversationId, null, null, messageType, personNumber);
      fetchChatMessages(conversationId);
      fetchConnectStatus(conversationId, personId || conversationPersonMapping[conversationId]);
    }
  }

  sendMessage(message, selectedPhone) {
    const { conversationId, messageType, addNewMessageToConversation, sendChatMessage } = this.props;

    if (messageType === 'Chat') {
      sendChatMessage({ message, conversationId });
    } else {
      addNewMessageToConversation(conversationId, {
        MessageType: messageType,
        Body: message,
        IsByPerson: false,
        To: [selectedPhone],
      });
    }
  }

  getHeaderSubText = () => {
    const { conversationId, connectNotifications, jobGuidToIdMapping, jobsById, messageType } = this.props;
    if (!conversationId || !['Chat', 'SMS'].includes(messageType)) return '';
    const currentConversationNotification = connectNotifications?.find(
      notification => notification?.Payload?.ConversationId === conversationId
    );
    const jobGuid = currentConversationNotification?.Payload.RefId;
    if (!jobGuid) return '';
    const jobDetails = _.get(jobsById, [jobGuidToIdMapping[jobGuid]], {});
    if (!jobDetails?.JobTitle || !jobDetails?.JobId) return '';
    return `${jobDetails.JobTitle} - ${jobDetails.JobId}`;
  };

  render() {
    const {
      conversationId,
      personId,
      personsById,
      conversationPersonMapping,
      messageType,
      connectInfo,
      featureToggleList,
      connectApiStatus,
      userByGuId,
      history,
      notificationPayload,
      saveRecentReadNotificationInfo,
      orgPhoneConsentInfo,
    } = this.props;
    const { messages, consentStatus, selectedPhone, phones, consentInfo, jobDetails } = this.state;
    const subscriptionData = _.get(
      connectInfo,
      ['ConnectStatuses', personId || conversationPersonMapping[conversationId]],
      {}
    )?.Contact?.Subscription?.Status;
    const candidateConnectInfo = _.get(
      connectInfo,
      ['ConnectStatuses', personId || conversationPersonMapping[conversationId]],
      {}
    );
    const personDetails = _.get(personsById, personId || [conversationPersonMapping[conversationId]], {});
    return (
      <MessagePopup
        className="chat-message-popup"
        conversationId={conversationId}
        consentStatus={consentStatus}
        selectedPhone={selectedPhone}
        onChangePhone={this.onChangePhone}
        messages={messages}
        phones={phones}
        onClose={this.onCloseMessagePopup}
        personDetails={personDetails}
        messageType={messageType}
        sendMessage={this.sendMessage}
        connectInfo={candidateConnectInfo}
        subscription={subscriptionData}
        headerSubText={this.getHeaderSubText()}
        personId={personId}
        featureToggleList={featureToggleList}
        connectApiStatus={connectApiStatus}
        userByGuId={userByGuId}
        history={history}
        notificationPayload={notificationPayload}
        saveRecentReadNotificationInfo={saveRecentReadNotificationInfo}
        consentInfo={consentInfo}
        jobDetails={jobDetails}
        orgPhoneConsentInfo={orgPhoneConsentInfo}
      />
    );
  }
}

export { MessagePopupContainer as MessagePopupContainerWithoutStore };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessagePopupContainer));
