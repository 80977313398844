/* eslint-disable sonarjs/no-all-duplicated-branches */
import React from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Modal, Tooltip, Avatar, Popover, Input } from 'antd';
import classNames from 'classnames';
import uuid from 'uuid/v4';
import _ from 'lodash';
import './DemoCandidate360MailAndMessageComposeWindow.scss';
import CloseMailAndMessageComposeWindowModal from './CloseMailAndMessageComposeWindowModal';
import message from '../../../Containers/CandidateDrawer/messages';
import ChatTextHistory from '../ChatTextHistory/ChatTextHistory';
import { formatTextMessage } from '../../../Utils/TextUtils';

export const getSendButtonWithTooltip = ({ shouldShowTooltip, sendButtonTooltipText, sendButton }) => {
  return shouldShowTooltip ? (
    <Tooltip title={sendButtonTooltipText} overlayStyle={{ zIndex: '2229' }} placement="topLeft">
      {sendButton}
    </Tooltip>
  ) : (
    sendButton
  );
};

const { MessageBubble: MessageBubbleFromTextHistory } = ChatTextHistory;

function DemoCandidate360MailAndMessageComposeWindow(props) {
  const {
    isModalVisible,
    setModalVisibility,
    id,
    htmlType,
    isSendDisabled,
    handleSubmit,
    composeModalTitle,
    context,
    candidate360MessageComposeSectionPayload,
    getCandidate360MailWindow,
    getCandidate360MessageWindow,
    preHeaderVisibility,
    onCloseEmailComposer,
    shouldShowTooltip,
    sendButtonTooltipText,
    jobId,
    jobTitle,
    candidateContext,
    version,
    jobCode,
    readChat,
    conversationId,
    currentValues = {},
    setPhoneNumber,
    handleCandidateResponse,
    selectedPhoneNumberMessages,
    getReportConversationDetails,
    phoneNumber,
    isComposeMessageDisabled,
  } = props;
  const [isEmailModalMinimized, minimizeEmailModal] = React.useState(context === 'message');
  const [isCloseModalVisible, setCloseModalVisibility] = React.useState(false);
  const [sizeTooltipVisiblity, setSizeTooltipVisiblity] = React.useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);
  const [candidateInputResponse, setCandidateInputResponse] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const chatRef = React.useRef(null);

  React.useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [selectedPhoneNumberMessages?.length]);

  React.useEffect(() => {
    getReportConversationDetails(conversationId);
  }, []);

  const onPopoverClick = () => {
    setIsPopoverVisible(true);
  };

  const onPopoverCloseClick = () => {
    setIsPopoverVisible(false);
  };

  const onCandidateResponse = value => {
    const candidateResponse = value;
    setCandidateInputResponse(candidateResponse);
  };

  const onSubmitCandidateResponse = async e => {
    e.stopPropagation();
    if (isSubmitting) return;
    const candidateResponse = candidateInputResponse.trim();
    if (!candidateResponse) return;
    setIsSubmitting(true);
    try {
      await handleCandidateResponseSubmit(candidateResponse);
      setCandidateInputResponse('');
    } catch (error) {
      console.error('Error submitting candidate response:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const findRecruitersLastMessage = messages => {
    const validMessage = messages?.find(
      msg => msg.To && msg?.IsByPerson === false && (msg?.IsByBot === true || !msg?.IsByBot)
    );

    return validMessage || null;
  };

  const handleCandidateResponseSubmit = async candidateResponse => {
    const { selectedPhone } = currentValues;
    const recruiterLastMessage = findRecruitersLastMessage(selectedPhoneNumberMessages);
    let payload = {
      body: candidateResponse,
      original_message_id: uuid(), // Need to update
      timestamp: Math.floor(Date.now() / 1000),
      from: selectedPhone.Number,
      custom_string: recruiterLastMessage.Id,
    };

    payload = { ...payload, to: recruiterLastMessage.From };
    await handleCandidateResponse(conversationId, payload);
    setCandidateInputResponse('');
  };

  const getPopoverContainer = () => {
    return (
      <div className="mobile-container">
        <div className="mobile-screen-container">
          <div className="header">
            <div className="left-icon">
              <Icon type="left" style={{ fontSize: '12px' }} />
            </div>
            <div className="contact-info">
              <Avatar icon="user" style={{ backgroundColor: '#C9C9CC', color: '#4F4D55' }} size="small" />
              <span className="phone-number">+918647867687, India</span>
            </div>
          </div>
          <div className="message-container" ref={chatRef}>
            <span style={{ fontSize: '12px' }}>SMS</span>
            <div className="message-box">
              <ChatTextHistory className="connect-chat-window" isComposeMessageDisabled={isComposeMessageDisabled}>
                {selectedPhoneNumberMessages.map(currentMessage => (
                  <MessageBubbleFromTextHistory
                    key={currentMessage.Id}
                    message={formatTextMessage(currentMessage.Body, true)}
                    timestamp={currentMessage.CreatedTime}
                    createdByName={currentMessage.CreatedByName}
                    align={!currentMessage.IsByPerson ? 'left' : 'right'}
                    className={!currentMessage.IsByPerson ? 'leftText' : 'rightText'}
                    type={currentMessage.type}
                    DeliveryStatus={currentMessage.DeliveryStatus}
                    RequestStatus={currentMessage.RequestStatus}
                    messageDeliveryStatusInformation={currentMessage.MessageDeliveryStatusInformation}
                    readOnly
                    hideTextFooter
                  />
                ))}
              </ChatTextHistory>
            </div>
          </div>
          <div className="input-container">
            <div
              className="input-button-wrapper"
              style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                padding: '4px',
                backgroundColor: '#f5f5f5',
                width: '95%',
              }}
            >
              <Input
                placeholder="Hey, Hi"
                className="input"
                onChange={e => onCandidateResponse(e.target.value)}
                value={candidateInputResponse}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onSubmitCandidateResponse(e);
                  }
                }}
                style={{ border: 'none', outline: 'none', backgroundColor: 'transparent', boxShadow: 'none' }}
                disabled={selectedPhoneNumberMessages?.length === 0}
              />
              <Button
                type="primary"
                shape="circle"
                icon="arrow-right"
                onClick={e => onSubmitCandidateResponse(e)}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 'none',
                  backgroundColor: 'transparent',
                  color: '#0b93f6',
                  fontSize: '16px',
                  boxShadow: 'none',
                }}
                disabled={selectedPhoneNumberMessages?.length === 0}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    if (context === 'message') {
      const { selectedPhone } = currentValues;
      if (conversationId && selectedPhone) {
        setPhoneNumber(selectedPhone.Number);
      }
      readChat();
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (context === 'message') {
        readChat();
      }
    };
  }, []);

  const modalContent =
    context === 'email'
      ? getCandidate360MailWindow()
      : getCandidate360MessageWindow(candidate360MessageComposeSectionPayload);

  const getModalBodyStyle = () => {
    const minimizedModalStyle = {
      height: '84%',
      backgroundColor: '#fafafb',
      padding: '0px',
    };
    const maximizedModalStyle = {
      height: context === 'email' ? 'calc(100vh - 91px)' : 'calc(100vh - 49px)',
      padding: '0px',
      backgroundColor: '#FAFAFA',
    };
    if (context === 'email') {
      minimizedModalStyle.padding = '4px 0 24px 24px';
      maximizedModalStyle.padding = '0 0 0 24px';
    }
    return isEmailModalMinimized ? minimizedModalStyle : maximizedModalStyle;
  };

  const handleOnClose = () => {
    setModalVisibility(false);
    if (onCloseEmailComposer) onCloseEmailComposer();
    if (isCloseModalVisible) setCloseModalVisibility(false);
  };

  const handleOnClickCloseIcon = () => {
    if (isComposeMessageDisabled) setModalVisibility(false);
    else setCloseModalVisibility(true);
  };

  const handleOnSend = event => {
    setModalVisibility(false);
    handleSubmit(event);
    if (onCloseEmailComposer) onCloseEmailComposer();
  };

  const changeModalSize = () => {
    minimizeEmailModal(!isEmailModalMinimized);
    setSizeTooltipVisiblity(false);
  };
  const toggleTooltipVisibilty = value => {
    setSizeTooltipVisiblity(value);
  };
  const getModalContentClassname = () => {
    if (!preHeaderVisibility && isEmailModalMinimized) return 'candidate-360-view-modal-content';
    if (!preHeaderVisibility && !isEmailModalMinimized) return 'candidate-360-view-maximized-modal-content';
    return '';
  };

  const changeSizeIcon = isEmailModalMinimized ? 'arrows-alt' : 'shrink';
  const changeSizeButtonTooltipText = isEmailModalMinimized ? (
    <FormattedMessage {...message.expandLabel} />
  ) : (
    <FormattedMessage {...message.shrinkLabel} />
  );
  const tooltipPlacement = 'bottom';

  const modalContentClassname = getModalContentClassname();

  const sendButton = (
    <Button
      shape="round"
      key="submit"
      type="primary"
      id={id}
      htmlType={htmlType}
      disabled={isSendDisabled}
      onClick={handleOnSend}
    >
      <FormattedMessage {...message.sendButton} />
    </Button>
  );

  const displayedSendButton = getSendButtonWithTooltip({ shouldShowTooltip, sendButtonTooltipText, sendButton });

  const footerButtons = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <div className="action-buttons"></div>
      <div className="action-buttons">
        <Button shape="round" key="back" onClick={handleOnClose}>
          <FormattedMessage {...message.cancelButton} />
        </Button>
        {displayedSendButton}
      </div>
    </div>
  );

  const { selectedPhone } = currentValues;
  const number = phoneNumber || undefined;
  const selectedPhoneNumber = selectedPhone?.Number || number;
  const messageHistoryTitle = (
    <span>
      <FormattedMessage {...message.messageHistoryLabel} /> &nbsp;-&nbsp; ({selectedPhoneNumber})
    </span>
  );

  const title = (
    <div className="candidate-360-compose-modal-title" style={jobTitle ? { fontWeight: '400' } : {}}>
      <span className="compose-modal-title">{isComposeMessageDisabled ? messageHistoryTitle : composeModalTitle}</span>
      {jobTitle && !isComposeMessageDisabled && (
        <Tooltip title={jobTitle} placement="bottomLeft" overlayStyle={{ zIndex: 3000 }}>
          <span style={jobTitle ? { flex: '1', textTransform: 'capitalize' } : {}}>
            &nbsp;-&nbsp;
            {candidateContext === 'job' ? (
              <FormattedMessage {...message.jobTitleLabel} />
            ) : (
              <FormattedMessage {...message.segmentTitleLabel} />
            )}
            : {_.truncate(jobTitle, { length: 43 })}, ID: {version === 'ats' ? jobCode : jobId}
          </span>
        </Tooltip>
      )}

      <div className="expand-shrink-icons">
        <div onMouseMove={() => toggleTooltipVisibilty(true)} onMouseLeave={() => toggleTooltipVisibilty(false)}>
          <Tooltip
            title={changeSizeButtonTooltipText}
            placement={tooltipPlacement}
            overlayStyle={{ zIndex: '2229' }}
            visible={sizeTooltipVisiblity}
          >
            <Icon type={changeSizeIcon} onClick={changeModalSize} />
          </Tooltip>
        </div>
        <Icon type="close" onClick={handleOnClickCloseIcon} />
      </div>
    </div>
  );
  return (
    <>
      <div>
        <Modal
          className={classNames('candidate-360-compose-modal', {
            'maximized-modal': !isEmailModalMinimized,
          })}
          title={title}
          visible={isModalVisible}
          zIndex={2222}
          footer={context === 'email' ? [footerButtons] : false}
          wrapClassName="modal-container"
          onCancel={handleOnClose}
          bodyStyle={getModalBodyStyle()}
          closable={false}
          maskClosable={false}
          style={{ maxHeight: '100vh', overflow: 'hidden' }}
        >
          <div
            className={classNames(modalContentClassname, {
              'maximized-modal-content': !isEmailModalMinimized,
            })}
          >
            {modalContent}
          </div>
        </Modal>
        {isCloseModalVisible ? (
          <CloseMailAndMessageComposeWindowModal
            isCloseModalVisible={isCloseModalVisible}
            setCloseModalVisibility={setCloseModalVisibility}
            onCloseComposeWindow={handleOnClose}
            context={context}
            isComposeMessageDisabled={isComposeMessageDisabled}
          />
        ) : null}
      </div>
      <div>
        {document.getElementById('demo-chatbot') &&
          createPortal(
            <div>
              {' '}
              <Popover
                trigger="click"
                visible={isPopoverVisible}
                overlayClassName="tooltip-class"
                content={getPopoverContainer()}
                zIndex={2222}
                placement="topLeft"
                getPopupContainer={_ => document.body}
              >
                {!isPopoverVisible ? (
                  <div onClick={onPopoverClick}>
                    <Avatar
                      size={64}
                      style={{ zIndex: 30000, backgroundColor: '#ECECED', color: '#7F7D83' }}
                      icon={<Icon type="user" />}
                    />
                  </div>
                ) : (
                  <div onClick={onPopoverCloseClick}>
                    <Avatar
                      size={64}
                      style={{ zIndex: 30000, backgroundColor: '#ECECED', color: '#1F2730' }}
                      icon={<Icon type="close" />}
                    />
                  </div>
                )}
              </Popover>
            </div>,
            document.getElementById('demo-chatbot')
          )}
      </div>
    </>
  );
}

export default DemoCandidate360MailAndMessageComposeWindow;
