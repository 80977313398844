import _ from 'lodash';
import cleanSet from 'clean-set';

function addNewMessage(state, action, identifierKey, id) {
  const newState = _.cloneDeep(state);
  const newMessage = action.payload.Message;
  let pendingMessages;
  let messages;
  if (newMessage.IsByPerson) {
    pendingMessages = _.get(
      state,
      ['Pending', identifierKey, id, 'ByPhoneNumber', action.payload.Message.From, action.payload.conversationId],
      []
    );
    pendingMessages = pendingMessages.filter(message => message.Id !== action.payload.Message.Id);
    _.setWith(
      newState,
      ['Pending', identifierKey, id, 'ByPhoneNumber', action.payload.Message.From, action.payload.conversationId],
      pendingMessages,
      Object
    );
    messages = _.get(
      state,
      [identifierKey, id, 'ByPhoneNumber', action.payload.Message.From, action.payload.conversationId, 'Messages'],
      []
    );
    let { unreadSmsCount } = (messages && messages.length > 0 && messages[0]) ?? {};
    if (!unreadSmsCount) unreadSmsCount = 0;
    unreadSmsCount += 1;
    const messageWithUnreadSmsCount = {
      ...action.payload.Message,
      unreadSmsCount,
    };
    const receivedMessageGuid = action.payload.Message.Id;
    const index = messages.findIndex(message => message.Id === receivedMessageGuid);
    if (index !== -1) messages[index] = messageWithUnreadSmsCount;
    else messages.unshift(messageWithUnreadSmsCount);
    _.setWith(
      newState,
      [identifierKey, id, 'ByPhoneNumber', messageWithUnreadSmsCount.From, action.payload.conversationId, 'Messages'],
      _.uniqBy(messages, 'Id'),
      Object
    );
    const messageObject = { ..._.get(state, [identifierKey, id], {}) };
    if (!messageObject.LatestMessageIds) {
      messageObject.LatestMessageIds = [];
    }
    if (!messageObject.TotalCount) {
      messageObject.TotalCount = 0;
    }
    if (
      !messageObject.LatestMessageIds.includes(`${messageWithUnreadSmsCount.From}_${action.payload.conversationId}`)
    ) {
      messageObject.LatestMessageIds.push(`${messageWithUnreadSmsCount.From}_${action.payload.conversationId}`);
      messageObject.TotalCount += 1;
      _.setWith(newState, [identifierKey, id], messageObject, Object);
    }
  } else {
    pendingMessages = _.get(
      state,
      ['Pending', identifierKey, id, 'ByPhoneNumber', action.payload.Message.To, action.payload.conversationId],
      []
    );
    pendingMessages = pendingMessages.filter(message => message.Id !== action.payload.Message.Id);
    _.setWith(
      newState,
      ['Pending', identifierKey, id, 'ByPhoneNumber', action.payload.Message.To, action.payload.conversationId],
      pendingMessages,
      Object
    );
    messages = _.get(
      state,
      [identifierKey, id, 'ByPhoneNumber', action.payload.Message.To, action.payload.conversationId, 'Messages'],
      []
    );
    let { unreadSmsCount } = (messages && messages.length > 0 && messages[0]) ?? {};
    if (!unreadSmsCount) unreadSmsCount = 0;
    const messageWithUnreadSmsCount = {
      ...action.payload.Message,
      unreadSmsCount,
    };
    messages.unshift(messageWithUnreadSmsCount);
    _.setWith(
      newState,
      [identifierKey, id, 'ByPhoneNumber', messageWithUnreadSmsCount.To, action.payload.conversationId, 'Messages'],
      _.uniqBy(messages, 'Id'),
      Object
    );
    const messageObject = { ..._.get(state, [identifierKey, id], {}) };
    if (!messageObject.LatestMessageIds) {
      messageObject.LatestMessageIds = [];
    }
    if (!messageObject.TotalCount) {
      messageObject.TotalCount = 0;
    }
    if (!messageObject.LatestMessageIds.includes(`${messageWithUnreadSmsCount.To}_${action.payload.conversationId}`)) {
      messageObject.LatestMessageIds.push(`${messageWithUnreadSmsCount.To}_${action.payload.conversationId}`);
      messageObject.TotalCount += 1;
      _.setWith(newState, [identifierKey, id], messageObject, Object);
    }
  }

  return newState;
}

function setConversationMessages(state, action, identifierKey, id) {
  const newState = _.cloneDeep(state);
  const messages = _.get(
    state,
    [identifierKey, id, 'ByPhoneNumber', action.payload.PhoneNumber, action.payload.currentConversationId, 'Messages'],
    []
  );
  const messagesIds = messages.map(message => message.Id);

  let pendingMessages = _.get(
    state,
    ['Pending', identifierKey, id, 'ByPhoneNumber', action.payload.PhoneNumber, action.payload.currentConversationId],
    []
  );
  pendingMessages = pendingMessages.filter(message => !messagesIds.includes(message.Id));
  pendingMessages = pendingMessages.map(message => ({ ...message, Status: 'Pending' }));

  _.setWith(
    newState,
    ['Pending', identifierKey, id, 'ByPhoneNumber', action.payload.PhoneNumber, action.payload.currentConversationId],
    pendingMessages,
    Object
  );

  _.setWith(
    newState,
    [identifierKey, id, 'ByPhoneNumber', action.payload.PhoneNumber, action.payload.currentConversationId, 'Messages'],
    [
      ...(messages || []),
      ...(action.payload.Messages || []).filter(
        payloadMessage => !messages.some(existingMessage => existingMessage.Id === payloadMessage.Id)
      ),
    ],
    Object
  );

  _.setWith(
    newState,
    [
      identifierKey,
      id,
      'ByPhoneNumber',
      action.payload.PhoneNumber,
      action.payload.currentConversationId,
      'MessagesTotal',
    ],
    action.payload.total,
    0
  );
  return newState;
}

function setLatestMessages(state, identifierKey, identifierId, latestMessages, TotalCount) {
  const newState = _.cloneDeep(state);
  const messages = _.get(state, [identifierKey, identifierId], {});
  const messageObject = { ...messages };

  latestMessages.forEach(message => {
    const conversationId = message.LatestMessage.ConversationId;
    const tempObj = {
      ...message.LatestMessage,
      unreadSmsCount: message.UnreadSmsCount,
    };

    if (!messageObject.ByPhoneNumber) {
      messageObject.ByPhoneNumber = {};
    }
    if (!messageObject.LatestMessageIds) {
      messageObject.LatestMessageIds = [];
    }

    if (!messageObject.ByPhoneNumber[message.PhoneNumber]) {
      messageObject.ByPhoneNumber[message.PhoneNumber] = {};
    }

    if (!messageObject.ByPhoneNumber[message.PhoneNumber][conversationId]) {
      messageObject.ByPhoneNumber[message.PhoneNumber][conversationId] = {};
    }

    if (!messageObject.ByPhoneNumber[message.PhoneNumber][conversationId].Messages) {
      messageObject.ByPhoneNumber[message.PhoneNumber][conversationId].Messages = {};
    }

    messageObject.ByPhoneNumber[message.PhoneNumber][conversationId].Messages = [tempObj];
    messageObject.LatestMessageIds.push(`${message.PhoneNumber}_${conversationId}`);
  });

  messageObject.TotalCount = TotalCount;
  _.setWith(newState, [identifierKey, identifierId], messageObject, Object);

  return newState;
}

function MessageConversationsReducer(state = {}, action) {
  let newState;
  let pendingMessages;
  const { isAllConversationsEnabled } = action.payload || {};
  switch (action.type) {
    case 'SET_CONVERSATION_MESSAGES':
      newState = _.cloneDeep(state);
      if (!newState.ByConversationId) {
        newState.ByConversationId = {};
      }
      if (!newState.ByPersonId) {
        newState.ByPersonId = {};
      }
      if (action.payload.PhoneNumber) {
        if (action.payload.conversationId) {
          newState = setConversationMessages(state, action, 'ByConversationId', action.payload.conversationId);
        } else if (action.payload.personId && isAllConversationsEnabled) {
          newState = setConversationMessages(state, action, 'ByPersonId', action.payload.personId);
        }
      }
      return newState;
    case 'RESET_CONVERSATION_MESSAGES': {
      newState = _.cloneDeep(state);
      if (action.payload.conversationId) {
        _.set(
          newState,
          ['ByConversationId', action.payload.conversationId, 'ByPhoneNumber', action.payload.PhoneNumber, 'Messages'],
          {}
        );
      }
      if (action.payload.personId && isAllConversationsEnabled) {
        _.set(
          newState,
          ['ByPersonId', action.payload.personId, 'ByPhoneNumber', action.payload.PhoneNumber, 'Messages'],
          {}
        );
      }
      return newState;
    }
    case 'SET_LATEST_MESSAGES': {
      const identifierKey = action.payload.conversationId ? 'ByConversationId' : 'ByPersonId';
      const identifierId = action.payload.conversationId || action.payload.personId;
      newState = _.cloneDeep(state);
      if (action.payload.conversationId || action.payload.personId) {
        const { messages: latestMessages, Total: TotalCount } = action.payload;

        if (action.payload.userId && latestMessages) {
          newState = setLatestMessages(state, identifierKey, identifierId, latestMessages, TotalCount);
        }
      }
      return newState;
    }
    case 'RESET_LATEST_MESSAGES': {
      const identifierKey = action.payload.conversationId ? 'ByConversationId' : 'ByPersonId';
      const identifierId = action.payload.conversationId || action.payload.personId;
      newState = _.cloneDeep(state);
      _.setWith(newState, [identifierKey, identifierId], {}, Object);
      return newState;
    }
    case 'SET_CONVERSATION_PENDING_MESSAGES':
      newState = _.cloneDeep(state);
      pendingMessages = _.get(
        newState,
        [
          'Pending',
          'ByConversationId',
          action.payload.conversationId,
          'ByPhoneNumber',
          action.payload.message.To,
          action.payload.conversationId,
        ],
        []
      );
      pendingMessages.unshift({ ...action.payload.message, Status: 'Pending' });
      _.setWith(
        newState,
        [
          'Pending',
          'ByConversationId',
          action.payload.conversationId,
          'ByPhoneNumber',
          action.payload.message.To,
          action.payload.conversationId,
        ],
        pendingMessages,
        Object
      );
      return newState;

    case 'ADD_NEW_MESSAGE':
      if (action.payload.Message.RequestStatus === 'Pending' && !action.payload?.isChatBotDemo) {
        return state;
      }
      newState = _.cloneDeep(state);
      if (!newState.ByConversationId) {
        newState.ByConversationId = {};
      }
      if (action.payload.conversationId) {
        newState = addNewMessage(newState, action, 'ByConversationId', action.payload.conversationId);
      }
      if (action.payload.personId && action.payload.isAllConversationsEnabled) {
        newState = addNewMessage(newState, action, 'ByPersonId', action.payload.personId);
      }
      return newState;
    default:
      return state;
  }
}

function getMessageConversations(state, conversationId) {
  const messages = _.cloneDeep(
    _.get(state.MessageConversationsReducer, ['ByConversationId', conversationId, 'ByPhoneNumber'], {})
  );
  let pendingMessages = _.cloneDeep(
    _.get(state.MessageConversationsReducer, ['Pending', 'ByConversationId', conversationId, 'ByPhoneNumber'], {})
  );
  pendingMessages = _.differenceBy(pendingMessages, messages, 'Id');
  return _.mergeWith(messages, pendingMessages, (objValue, srcValue) => {
    if (_.isArray(objValue) && _.isArray(srcValue)) {
      return [...srcValue, ...objValue];
    }
    return undefined;
  });
}

function getMessageConversationOfAllPhoneNumbers(state, conversationId) {
  return _.get(state.MessageConversationsReducer, ['ByConversationId', conversationId, 'ByPhoneNumber'], {});
}

function getMessageConversationOfAllPhoneNumbersCount(state, conversationId) {
  return _.get(state.MessageConversationsReducer, ['ByConversationId', conversationId, 'TotalCount'], 0);
}

function getSmsLatestMessageIds(state, conversationId) {
  return _.get(state.MessageConversationsReducer, ['ByConversationId', conversationId, 'LatestMessageIds'], 0);
}

function getAllMessageConversation(state, personId) {
  return _.get(state.MessageConversationsReducer, ['ByPersonId', personId, 'ByPhoneNumber'], {});
}

function getAllMessageConversationCount(state, personId) {
  return _.get(state.MessageConversationsReducer, ['ByPersonId', personId, 'TotalCount'], 0);
}

function getAllSmsLatestMessageIds(state, personId) {
  return _.get(state.MessageConversationsReducer, ['ByPersonId', personId, 'LatestMessageIds'], 0);
}

function getPendingMessageConversation(state, conversationId) {
  const pending = state.MessageConversationsReducer?.Pending;
  if (pending && pending.ByConversationId && pending.ByConversationId[conversationId]) {
    return pending.ByConversationId[conversationId].ByPhoneNumber || {};
  }
  return {};
}

function getAllPendingMessageConversation(state, personId) {
  const pending = state.MessageConversationsReducer?.Pending;
  if (pending && pending.ByPersonId && pending.ByPersonId[personId]) {
    return pending.ByPersonId[personId].ByPhoneNumber || {};
  }
  return {};
}

export {
  MessageConversationsReducer,
  getMessageConversations,
  getMessageConversationOfAllPhoneNumbers,
  getPendingMessageConversation,
  getAllMessageConversation,
  getAllPendingMessageConversation,
  getAllMessageConversationCount,
  getMessageConversationOfAllPhoneNumbersCount,
  getAllSmsLatestMessageIds,
  getSmsLatestMessageIds,
};
